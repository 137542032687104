<template>
    <div class="reset-container">
        <div class="wrapper">
            <p class="title">Reset your Password</p>
            <form @submit.prevent="reset()" class="reset-form">
                <input type="password" class="password" placeholder="New Password" v-model="password">
                <input type="password" class="confirm" placeholder="Confirm Password" v-model="confirm">

                <p class="_error" v-if="error.password">{{ error.password[0] }}</p>
                <button type="submit" class="submit" :disabled="loading">Reset Password</button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: ['token'],

    data () {
        return {
            password: '',
            confirm: ''
        }
    },

    created () {
        this.$store.commit('SET_RESET_ERROR', {})
    },

    computed: {
        ...mapState({
            error: state => state.resetError,
            loading: state => state.loading,
            language: state => state.language
        })
    },

    methods: {
        async reset () {
            let form = new FormData()

			form.append('password', this.password)
			form.append('password_confirmation', this.confirm)
			form.append('token', this.token)
			await this.$store.dispatch('resetPassword', form)
        }
    }
}
</script>

<style lang="scss" scoped>
.reset-container {
    width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;

    .wrapper {
        margin: auto;
        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 90px 40px 40px;
        border-radius: 4px;
        box-shadow: 0 3px 6px #00000047;

        background-image: url('~@/assets/img/home_upperbg.png');
        background-repeat: no-repeat;
        background-size: 100%;

        p {
            text-align: center;
            margin-bottom: 40px;
            font-weight: 600;
            font-size: 20px;
            color: #00AEEF;

            &._error {
                margin-bottom: 15px;
                font-size: 14px;
                text-align: center;
                color: #FF6868;
                font-weight: 400;
            }
        }

        input {
            width: 100%;
            border-radius: 4px;
            border: 1px solid #D1D1D1;
            padding: 10px 15px;
            font-size: 14px;
            color: #00000099;
            margin-bottom: 20px;
        }

        button {
            margin-top: 20px;
            width: 100%;
            color: #ffffff;
            background-color: #00AEEF;

            &:disabled {
                opacity: 0.7;
                pointer-events: none;
            }
        }
    }
}
</style>